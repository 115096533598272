


































































import {Advertise} from "@/request/marketing/Advertise";

import {Vue, Component} from "vue-property-decorator";

@Component({
    name: 'marketing_advertise'
})
export default class marketing_advertise extends Vue {
    modal: any = Advertise;
    searchLoading: boolean = false;
    search: Array<any> = [
        {
            type: 'Input',
            label: '名称',
            key: 'name',
            width: '120px',
            placeholder: '请填写名称'
        },
        {
            type: 'Select',
            label: '状态',
            key: 'status',
            clearable: true,
            width: '120px',
            items: [
                {
                    label: '下线',
                    value: 0
                },
                {
                    label: '上线',
                    value: 1
                }
            ]
        },
        {
            type: 'DatePicker',
            pickerType: 'datetimerange',
            format: 'yyyy-MM-dd HH:mm',
            label: '日期',
            width: '220px',
            placeholder: '创建时间'
        }
    ];
    formData: any = {
        name: '',
        cover: '',
        goodsId: '',
        content: ''
    };
    formRules: any = {
        name: {required: true, message: '请填写名称', trigger: 'blur'},
    };
    columns: Array<any> = [
        {
            title: '名称',
            key: 'name',
            minWidth: 120
        },
        {
            title: '宣传封面',
            key: 'cover',
            minWidth: 120,
            render: (h: any, params: any) => {
                return h('img', {
                    style: {
                        borderRadius: '3px',
                        height: '100px',
                        width: '200px',
                        marginTop: '5px'
                    },
                    attrs: {
                        src: params.row.cover
                    }
                })
            }
        },
        {
            title: '创建时间',
            key: 'createTime',
            minWidth: 120
        }
    ];
    createNew() {
        let self: any = this;
        delete this.formData.id;
        self.$refs['el-editor'].setHtml('');
    }
    edit(row: any) {
        let self: any = this;
        this.formData = row;
        self.$refs['el-editor'].setHtml(row.content);
    }
    submit() {
        let self: any = this;
        let params = {...self.formData};
        self.$refs['el-form'].validate((valid: boolean) => {
            if (valid) {
                self.$refs['el-table'].doSubmit(params).then(() => {

                });
            }
        })
    }
    uploadCoverSuccess(url: string) {
        this.formData.cover = url;
    }
    goodsNameChange(name: string) {
        console.log('change------------', name)
    }
    goodsRemoteSearch(query: string) {
        console.log('search-----------', query)
    }
    clearGoods() {

    }
}

